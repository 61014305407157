import { useRef, useState } from 'react';
import React from 'react';
import Slider from 'react-slick';
import { motion } from 'framer-motion';
import { useTheme } from 'styled-components';

import { MotionDiv } from '../../animations/components';
import { animationContainer, fadeInUp } from '../../animations/variants';
import img1 from '../../assets/images/explanation-nusery-img-1.png';
import img2 from '../../assets/images/explanation-nusery-img-2.png';
import img3 from '../../assets/images/explanation-nusery-img-3.png';
import useContentAnimation from '../../hooks/use-content-animation';
import { useMatchMedia } from '../../hooks/use-match-media';
import { ScContainer } from '../container/styled';
import { ScParagraph } from '../Paragraph/styled';
import { ScGogetaNurseryExplainedWrapper, ScTitle } from '../Title/styled';

import {
	ScWideSliderContainer,
	ScWideSliderSlideDot,
	ScWideSliderSlideDots,
	ScWideSliderSlideTitle,
	ScWideSliderSlideWrapper,
	ScWideSliderTextWrapper,
	ScWideSliderWrapper,
} from './styled';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export interface ISlide {
	title: string;
	image: string;
	color?: string;
	isLarge?: boolean;
}

const slides = [
	{
		title: 'We help spread the news',
		text: 'Everything you need to promote the benefit is in your employer portal and we host webinars for employees to answer any questions they have.',
		image: img1,
	},
	{
		title: 'We make sorting admin and payroll a breeze',
		text: 'Once a month, we’ll send an invoice and payroll deductions to your payroll team. We work to your payroll dates, The nursery is then paid directly by us. Simple.',
		image: img2,
	},
	{
		title: 'We help with staff retention',
		text: 'Our employee portal lets employees keep track of their savings every month and over the year - great for retention.',
		image: img3,
	},
];

export default function GogetaNurseryExplained() {
	const [activeSlide, setActiveSlide] = useState(0);
	const sliderRef = useRef<Slider | null>(null);
	const theme = useTheme();
	const isDesktop = useMatchMedia(`(min-width: 1220px)`);
	const { ref, controls } = useContentAnimation();

	const settings = {
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: false,
		beforeChange: (_current: number, next: number) => setActiveSlide(next),
	};
	return (
		<ScContainer>
			<ScGogetaNurseryExplainedWrapper>
				<ScTitle centered>What sets Gogeta apart?</ScTitle>
				<motion.div initial="hidden" animate={controls} variants={animationContainer}>
					<MotionDiv variants={fadeInUp}>
						<ScWideSliderContainer>
							<ScWideSliderTextWrapper color={theme.colors.peach}>
								<ScWideSliderSlideTitle>{slides[activeSlide].title}</ScWideSliderSlideTitle>
								<ScParagraph centered={!isDesktop}>{slides[activeSlide].text}</ScParagraph>
								<ScWideSliderSlideDots>
									{slides.map((item, index) => (
										<ScWideSliderSlideDot
											key={item.title}
											className={index === activeSlide ? 'active-dot' : 'dot'}
											onClick={() => {
												sliderRef.current?.slickGoTo(index);
												setActiveSlide(index);
											}}
										/>
									))}
								</ScWideSliderSlideDots>
							</ScWideSliderTextWrapper>
							<ScWideSliderWrapper>
								<Slider {...settings} ref={sliderRef}>
									{slides.map((item) => (
										<ScWideSliderSlideWrapper key={item.title}>
											<img src={item.image as string} alt={'labtop-image'} />
										</ScWideSliderSlideWrapper>
									))}
								</Slider>
							</ScWideSliderWrapper>
						</ScWideSliderContainer>
						<div ref={ref} />
					</MotionDiv>
				</motion.div>
			</ScGogetaNurseryExplainedWrapper>
		</ScContainer>
	);
}
