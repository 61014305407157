import React, { useState } from 'react';
import { useTheme } from 'styled-components';

import { isNotSSR } from '@utils';

import { animationContainer, fadeInLeft, fadeInRight } from '../../animations/variants';
import image from '../../assets/images/employer-nursery-hero.png';
import ContactUsForm from '../../components/contact-us-form/ContactUsForm';
import { ScContainer } from '../../components/container/styled';
import { CustomButton } from '../../components/custom-button';
import Modal from '../../components/modal';
import { ScParagraph } from '../../components/Paragraph/styled';
import { ScTop } from '../../components/Top/styled';
import useContentAnimation from '../../hooks/use-content-animation';
import { useMatchMedia } from '../../hooks/use-match-media';
import { ScHeroContainer } from '../EmployeeCycling/styled';
import { ScEmployerHeroWrapper } from '../EmployerCycling/styled';

import { ScEmployerNurseryHeroLeft, ScEmployerNurseryHeroRight } from './styled';

export const EmployerNurseryHero = () => {
	const theme = useTheme();
	const isDesktop = useMatchMedia(`(min-width: 1220px)`);
	const { ref, controls } = useContentAnimation();
	const [isOpenModal, setIsOpenModal] = useState(false);
	const handleClick = () => {
		if (isNotSSR) {
			window.location.href = `${process.env.GATSBY_NURSERY_WEBSITE}/portal/employer_portal/register`;
		}
	};

	return (
		<ScTop>
			<Modal open={isOpenModal} title={'Contact us'} handleClose={() => setIsOpenModal(false)}>
				<ContactUsForm />
			</Modal>
			<ScHeroContainer ref={ref} initial="hidden" animate={controls} variants={animationContainer}>
				<ScContainer>
					<ScEmployerHeroWrapper>
						<ScEmployerNurseryHeroLeft variants={fadeInRight}>
							<img src={image} alt="bread" />
						</ScEmployerNurseryHeroLeft>
						<ScEmployerNurseryHeroRight variants={fadeInLeft}>
							<h3>Take the pressure off nursery bills</h3>
							<ScParagraph centered={!isDesktop} color={theme.colors.strongWhite}>
								Give your employees a break on nursery fees with our workplace nursery scheme.
							</ScParagraph>
							<div className="buttons">
								<CustomButton
									color={theme.colors.darkText}
									background={theme.colors.wheat}
									onClick={() => {
										setIsOpenModal(true);
									}}
								>
									Get in touch
								</CustomButton>
								<CustomButton
									color={theme.colors.darkText}
									background={theme.colors.wheat}
									onClick={handleClick}
								>
									Sign up
								</CustomButton>
							</div>
						</ScEmployerNurseryHeroRight>
					</ScEmployerHeroWrapper>
				</ScContainer>
			</ScHeroContainer>
		</ScTop>
	);
};
